<template>
  <div class="home flex-row">
    <div
      class="container-left"
      v-if="debrisLanguageType == 1"
      @click="goAboutDebris"
    >
      <span>关于DEBRIS</span>
    </div>
    <div
      class="container-left en-language"
      v-if="debrisLanguageType == 2"
      @click="goAboutDebris"
    >
      <span>About DEBRIS</span>
    </div>
    <div class="container-right flex-col">
      <div v-if="debrisLanguageType == 1" class="about-us" @click="goAboutUs">
        <span>关于我们</span>
      </div>
      <div v-if="debrisLanguageType == 2" class="about-us en-language" @click="goAboutUs">
        <span>About Us</span>
      </div>
      <div class="others flex-row">
        <div class="works-show" v-if="debrisLanguageType == 1" @click="goWorks">
          <span>作品展示</span>
        </div>
        <div class="works-show en-language" v-if="debrisLanguageType == 2" @click="goWorks">
          <span>Works</span>
        </div>
        <div class="contact-us flex-col justify-between">
          <span v-if="debrisLanguageType == 1">联系我们</span>
          <span class="en-txt" v-if="debrisLanguageType == 2">Contact</span>
          <span class="mail">debrisanimationstudio<br>@gmail.com</span>
          <span v-if="debrisLanguageType == 1" class="copy-btn" @click="copy"
            >&lt;复制地址></span
          >
          <span v-if="debrisLanguageType == 2" class="copy-btn en-txt" @click="copy"
            >&lt;copy></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      mail: "debrisanimationstudio@gmail.com",
    };
  },
  created() {},
  computed: {
    debrisLanguageType() {
      return this.$store.state.debrisLanguageType;
    },
  },
  methods: {
    goAboutDebris() {
      this.$router.push({ name: "AboutDebris" });
    },
    goAboutUs() {
      this.$router.push({ name: "AboutUs" });
    },
    goWorks() {
      this.$router.push({ name: "Works" });
    },
    copy() {
      // 创建input元素
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", this.mail);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
    },
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 8.16rem;
  background: url("../assets/image/others/home_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 9;
  .en-language{
    span{
      font-family: "Poppins Bold" !important;
    }
  }
  .container-left {
    width: 50%;
    height: 100%;                              
    transition: all 0.2s;
    cursor: pointer;
    padding: 0.24rem 0 0 0.24rem;
    background: rgba(38, 38, 38, 0.5);
    span {
      color: #ffffff;
      font-family: "Alibaba-PuHuiTi-Bold";
      display: none;
    }
    &:hover {
      background: rgba(38, 38, 38, 0);
      span {
        display: block;
      }
    }
  }

  .container-right {
    width: 50%;
    height: 100%;

    .about-us {
      width: 100%;
      height: 3.84rem;
      background: rgba(38, 38, 38, 0.5);
      transition: all 0.2s;
      padding: 0.24rem 0 0 0.24rem;
      cursor: pointer;
      span {
        color: #ffffff;
        font-family: "Alibaba-PuHuiTi-Bold";
        display: none;
      }
      &:hover {
        background: rgba(38, 38, 38, 0);
        span {
          display: block;
        }
      }
    }

    .others {
      width: 100%;
      height: 4.32rem;

      .works-show {
        width: 5.76rem;
        height: 100%;
        background: rgba(38, 38, 38, 0.5);
        transition: all 0.2s;
        padding: 0.24rem 0 0 0.24rem;
        cursor: pointer;
        span {
          color: #ffffff;
          font-family: "Alibaba-PuHuiTi-Bold";
          display: none;
        }
        &:hover {
          background: rgba(38, 38, 38, 0);
          span {
            display: block;
          }
        }
      }

      .contact-us {
        width: calc(100% - 5.76rem);
        height: 100%;
        background: rgba(38, 38, 38, 0.5);
        transition: all 0.2s;
        padding: 0.24rem 0.24rem 0.35rem;
        span {
          color: #ffffff;
          font-family: "Alibaba-PuHuiTi-Bold";
          display: none;
        }
        &:hover {
          background: rgba(38, 38, 38, 0);
          span {
            display: block;
          }
        }
        .mail {
          font-size: 0.36rem;
          word-wrap: break-word;
          display: none;
          font-family: "Poppins";
        }
        .copy-btn {
          cursor: pointer;
          display: none;
          font-family: "Alibaba-PuHuiTi-Regular";
        }
        .en-txt{
          font-family: "Poppins Bold" !important;
        }
      }
    }
  }
}
</style>
